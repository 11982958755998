import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import DocumentTitle from "react-document-title"
import Loader from "react-loader"
import queryString from 'query-string';
import Cookies from 'cookies-js';
import ProductStyle from "../../Components/ProductStyle";
import setCookieSameSite from '../../Utils/set-cookie-same-site';
import validateAccessApi from '../../Utils/validate';

var Bs = require('react-bootstrap')

export default function LoginSuccess(props) {

    const history = useHistory()

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        setTimeout(() => {
            handleRedirect()
        }, 1000)

    }, [])

    const handleRedirect = async () => {

        let params = queryString.parse(props.location.search)
        var data = params["data"];

        if (data) {        
            data = JSON.parse(atob((data)));
            if (data.access_token) {
                setCookieSameSite(config.cookie.name, JSON.stringify(data));
                setCookieSameSite('__vb.token', JSON.stringify(data));

            }            
        }       

        let loginData = params["t"];
        if (loginData) {
            setCookieSameSite('login.data', loginData);
        } else {
            loginData = Cookies.get('login.data');
        }

        ProductStyle.removeCookie();

        if (loginData) {
            loginData = JSON.parse(atob(loginData))
            if (loginData.successRedirect) {
                const token = Cookies.get(config.cookie.name);
                if (token) {
                    const productCookie = JSON.parse(token);
                    await validateAccessApi("product", productCookie.access_token);
                }
                document.location.href = loginData.successRedirect;
            }
        }

    }

    return (
        <DocumentTitle title='Login success'>
            <Loader loaded={!isLoading}>

            </Loader>
        </DocumentTitle>
    )

}
